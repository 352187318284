<template>
  <section id="surgical-note-container">
    <b-row>
        <b-col cols="10">
          <TabHeaderInfo
            :title="`Modelos de ${headerTitle}`"
            :tooltip="tooltip"
          >
          </TabHeaderInfo>
        </b-col>
        <b-col cols="2" class="newButton">
          <b-button variant="primary" @click="openSurgicalNote">
            Novo modelo de {{ headerTitle }}
          </b-button>
        </b-col>
      </b-row>

    <NoFilterResults
      v-if="!surgicalNotes.length"
      :message="noFilterMessage"
    />

    <b-container fluid class="cards mb-3 ml-0 p-0" v-else>
      <b-row>
        <b-col
          md="3"
          sm="12"
          class="mt-1 mb-2"
          v-for="surgucalNote in surgicalNotes"
          :key="surgucalNote.id"
        >
          <Card
            :item="surgucalNote"
            textLink="Ver modelo"
            :update="updateSurgicalNote"
            :canDelete="false"
            :edit="openSurgicalNote"
          />
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>

<script>
import { getCurrentUser } from '@/utils/localStorageManager'

export default {
  name: 'SurgicalNote',
  components: {
    TabHeaderInfo: () => import('@/components/General/TabHeaderInfo'),
    NoFilterResults: () => import('@/components/General/noFilterResults'),
    Card: () => import('@/components/SurgeryCenter/Configuration/Card')
  },
  props: {
    headerTitle: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    user: getCurrentUser(),
    surgicalNotes: []
  }),
  computed: {
    noFilterMessage() {
      return `Para iniciar, adicione um novo modelo de ${this.headerTitle}`
    },
    tooltip() {
      return {
        evolução:
          'Crie modelos de evolução e utilize-os no dashboard cirúrgico do paciente',
        anotação:
          'Crie modelos de anotação e utilize-os no dashboard cirúrgico do paciente',
        'anotação cirúrgica':
          'Crie modelos de anotações cirúrgicas e utilize-os dentro da consulta no campo “Anotação cirúrgica”.'
      }[this.headerTitle]
    }
  },
  async created() {
    await this.getAllFromProfessional()
  },
  methods: {
    openSurgicalNote({ item }) {
      if (item?.id) {
        this.$router.push(`/anotacao-cirurgica/${item.id}`)
        return
      }
      this.$router.push(`/anotacao-cirurgica`)
    },
    async getAllFromProfessional() {
      const isLoading = this.$loading.show()
      try {
        const { data } = await this.api.getAllSurgicalNotesFromProfessional(
          this.user.id
        )
        this.surgicalNotes = data.map(note => ({
          ...note,
          active: Boolean(note.active)
        }))
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
      }
    },
    async updateSurgicalNote(surgicalNote) {
      const isLoading = this.$loading.show()
      try {
        await this.api.updateSurgical(surgicalNote.id, {
          ...surgicalNote
        })
        if (surgicalNote.active) {
          this.$toast.success('Modelo ativado com sucesso!')
        } else {
          this.$toast.success('Modelo desativado com sucesso!')
        }
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.newButton {
  padding: 20px 0 30px 0;
  justify-content: flex-end;
  display: flex;
}
</style>